import React from "react";
import {Container,Row,Col} from "react-bootstrap";
import { Link } from "@reach/router";
import fbIcon from '../images/facebook.webp';
import gpIcon from '../images/googleplus.webp';
import liIcon from '../images/linkedin.webp';
import igIcon from '../images/instagram.webp';
import ttIcon from '../images/thumbtack.png';

const Footer = () => {
  // const data = useStaticQuery(graphql`
  //   query {
  //     site {
  //       siteMetadata {
  //         title
  //         contactEmail
  //         contactPhoneFmt
  //         contactPhoneUrl
  //       }
  //     },
  //   }
  // `);
  
  return (
  <footer>
    <Container fluid  style={{textAlign:'left', margin:0}}>
      <Row>
        <Col md>
          <ul>
            <li><Link to="/">Home</Link></li>
            {/*<li><Link to="/about">About Us</Link></li>*/}
            <li><Link to="/portfolio">Portfolio</Link></li>
            <li><Link to="/reviews">Customer Reviews</Link></li>
            <li><Link to="/roofing-contractor">Roofing</Link></li>
            {/* TODO LEADGEN 
            <li><Link to="/common-roofing-and-siding-problems">Common Problems</Link></li>
            */}
            <li><Link to="/roofing-contractor#certainteed">CertainTeed Roofing</Link></li>
            {/* <li><Link to="/roofing-contractor#gaf">GAF</Link></li> */}
          </ul>
        </Col>
        <Col md>
          <ul>
            <li><Link to="/vinyl-siding-contractor">Siding</Link></li>
            <li><Link to="/kitchen-and-bathroom-contractor">Kitchen & Bath</Link></li>
            <li><Link to="/new-construction-contractor">New Construction</Link></li>
            <li><Link to="/deck-contractor">Decking</Link></li>
            <li><Link to="/carpentry-contractor">Carpentry</Link></li>
          </ul>
        </Col>
        <Col sm>
          <ul>
            <li><a href="mailto:mlb_contracting@yahoo.com">mlb_contracting@yahoo.com</a></li>
            <li><a href="tel:+13157946223">(315)&nbsp;794&#8209;6223</a></li>
          </ul>
          <ul>
            {/*<li>1210&nbsp;Walnut&nbsp;St<br/>Utica,&nbsp;NY 13502</li>*/}
            <li>200&nbsp;Utica&nbsp;St<br/>Clinton,&nbsp;NY 13323</li>
          </ul>
        </Col>
      </Row>
      <Row>
        <Col>
          <ul className='cg-social'>
            <li><a href="https://www.facebook.com/misha.brishchuk"><img src={fbIcon} alt='Facebook' style={{width:'42px'}}/></a></li>
            <li><a href="https://www.linkedin.com/in/mikhail-brishchuk-627794144/"><img src={liIcon} alt='LinkedIn' style={{width:'42px'}}/></a></li>
            <li><a href="https://www.thumbtack.com/ny/utica/roofing/mlb-contracting-llc/service/251824188443034876"><img src={ttIcon} alt='Thumbtack' style={{width:'42px'}}/></a></li>
            <li><a href="https://www.instagram.com/mlb_contracting_llc/"><img src={igIcon} alt='Instagram' style={{width:'42px'}}/></a></li>
            <li><a href="https://www.google.com/search?source=hp&ei=E8NnWuX_JcGAsQXgqJygDA&q=mlb+contracting+llc&oq=mlb+&gs_l=psy-ab.1.0.35i39k1l2j0i131i67k1j0i67k1j0i131k1l2j0i67k1l3j0.4565.5946.0.7539.6.5.0.0.0.0.721.1300.2-1j1j6-1.4.0....0...1.1.64.psy-ab..2.4.1484.6..0i20i264k1j0i131i20i264k1j0i131i46i67k1j46i131i67k1.184.9UAaDUP2q6U"><img src={gpIcon} alt='Google' style={{width:'42px'}}/></a></li>
          </ul>
        </Col>
      </Row>
      <Row>
        <Col style={{textAlign:'center'}}>
          <p><strong>Serving Oneida county and surrounding area for {new Date().getFullYear() - 2005} years</strong></p> 
          <p>
            Service area: <a href="http://www.cityofutica.com/">Utica</a>
            , <a href="https://romenewyork.com/">Rome</a>
            , <a href="http://clarkmills.org/">Clark Mills</a>
            , <a href="https://www.townofherkimer.org/">Herkimer</a>
            , <a href="https://www.townoffrankfort.com/">Frankfort</a>
            , <a href="https://townmarcy.digitaltowpath.org">Marcy</a>
            , <a href="https://www.townofnewhartfordny.gov/">New Hartford</a>
            , <a href="https://newyorkmills.govoffice2.com/">New York Mills</a>
            , <a href="http://oneidacity.com/">Oneida</a>
            , <a href="http://localtown.us/sauquoit-ny/">Sauquoit</a>
            , <a href="https://www.townofvernon.com/">Vernon</a>
            , <a href="https://villagewhitesboro.digitaltowpath.org">Whitesboro</a>
            , <a href="https://townwestmoreland.digitaltowpath.org">Westmoreland</a>
          </p>
        </Col>
      </Row>
      <Row>
        <Col style={{ textAlign:'center', margin:'1rem', color:'#ccc' }}>
          <small><em>Website design and hosting by <a href='https://www.congius.org'>Congius</a> © {new Date().getFullYear()}</em></small>
        </Col>
      </Row>
    </Container>
  </footer>
)}

export default Footer
